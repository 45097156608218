.pagination {
  display: flex;
  box-sizing: border-box;
  color: #7e8299;
  box-shadow: none;
  gap: 0.6rem;
  padding: 1rem;
  margin: 0rem, 0rem, 1rem, 1rem;
}

.previous-page-btn,
.next-page-btn {
  background: #ffffff;
  border-radius: 25%;
  cursor: pointer;
}

.page-link {
  padding: 5px 10px 5px 10px;
  height: 25px;
  width: 25px;
}

.break {
  color: #7e8299;
  letter-spacing: 0.1rem;
}

.page-item.active a {
  background-color: #5eadff;
  padding: 5px 10px 5px 10px;
  border-radius: 25%;
  color: #ffffff;
}

.page-item {
  margin-left: 5px;
  align-self: center;
  cursor: pointer;
}

.custom-select {
  border-radius: 4px;
  font-weight: 500;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-text {
  visibility: hidden;
  width: max-content;
  max-width: 300px; /* Adjust as needed */
  background-color:skyblue;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  font-weight: bold;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  white-space: nowrap;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  transition: opacity 0.3s;
}

/* Show tooltip on hover */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Status text container */
.status-text-container {
  overflow: hidden;
  position: relative;
  max-width: 150px; /* Adjust as needed */
}

.status-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  transition: all 0.3s;
}

.status-text-container:hover .status-text {
  animation: scroll-text 10s linear infinite;
}

/* Animation for scrolling text */
@keyframes scroll-text {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}